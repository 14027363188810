import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedTermsTranslationKey } from '@unifii/library/common';
import { MfaChallengeType, OAuthWithMfaRecoveryCode, OAuthWithMfaSms, OAuthWithVirtualMfa, SMSChallenge, TenantClient, UfRequestError, ensureUfRequestError, isDictionary, isOptionalType, isValueOfStringEnumType } from '@unifii/sdk';
import { MfaStatus } from '@unifii/user-provisioning';
import { isString } from 'markdown-it/lib/common/utils';

import { UcClient } from 'client';
import { UrlSegments } from 'constant';
import { AuthenticationService, isPasswordChangeRequiredErrorData } from 'services/authentication.service';
import { ContextService } from 'services/context.service';

import { PasswordChangeComponentNavigationState } from './password-change.component';

export interface MfaComponentNavigationState {
    mfaStatus: MfaStatus;
    challenge?: `${MfaChallengeType}`;
    acceptedChallenges?: string;
    password?: string;
}

export const isMfaComponentNavigationState = (data: unknown): data is MfaComponentNavigationState =>
    isDictionary(data) &&
    isValueOfStringEnumType(MfaStatus)(data.mfaStatus) &&
    isOptionalType(data.challenge, isValueOfStringEnumType(MfaChallengeType)) &&
    isOptionalType(data.acceptedChallenges, isString) &&
    isOptionalType(data.password, isString);

@Component({
    selector: 'uc-mfa',
    templateUrl: 'mfa.html',
    styleUrls: ['mfa.less'],
})
export class MFAComponent implements OnInit {

    protected readonly mfaChallengeType = MfaChallengeType;
    protected readonly sharedTermsTK = SharedTermsTranslationKey;
    protected readonly mfaStatuses = MfaStatus;
    protected label: string | undefined;
    protected mfaStatus: MfaStatus | undefined;
    protected inProgress = false;
    protected error: string | undefined;
    protected challenge?: `${MfaChallengeType}`;
    protected acceptedChallenges: `${MfaChallengeType}`[] = [];
    protected user: PublicKeyCredentialUserEntity;

    private client = inject(UcClient);
    private router = inject(Router);
    private context = inject(ContextService);
    private authService = inject(AuthenticationService);
    private route = inject(ActivatedRoute);
    private tenantClient = inject(TenantClient);
    private state: MfaComponentNavigationState = history.state; // type assumed by mfa-guard

    async ngOnInit() {

        this.challenge = this.state.challenge;
        this.acceptedChallenges = this.createAcceptedChallenges();

        if (this.state.mfaStatus === MfaStatus.MfaSetupRequired) {
            await this.setUpMfa();
        }

        this.mfaStatus = this.state.mfaStatus;

        if (this.context.account) {
            this.user = {
                id: new TextEncoder().encode(this.context.account.id),
                name: this.context.account.username,
                displayName: this.context.account.username,
            };
        }
    }

    protected credential(credential: PublicKeyCredential): Promise<void> {
		console.log(credential);

		return Promise.resolve();
	}

    protected selectProvider(provider: MfaChallengeType) {
        this.error = undefined;
		this.challenge = provider;
	}

    protected async setRecoveryCodes(recoveryCodes: string[]):Promise<void> {
		await this.client.setRecoveryCodes(recoveryCodes);
        void this.router.navigateByUrl(this.route.snapshot.params.next || '/');
	}

    protected async setVirtualMfaCode(secret: string):Promise<void> {
        await this.client.setVirtualMfaCode(secret);
    }

    protected smsChallenges(): Promise<SMSChallenge> {
        return this.client.smsChallenges();
	}

    protected async verifyRecoveryCode(recovery_code: string): Promise<void> {
        if (this.inProgress) {
            return;
        }
        this.error = undefined;
        this.inProgress = true;
        try {
            await this.authService.login({ recovery_code } satisfies OAuthWithMfaRecoveryCode);
            this.handleVerifyAccepted();
        } catch (e) {
            this.handleVerifyError(ensureUfRequestError(e));
        } finally {
            this.inProgress = false;
        }
	}

    protected async verifySmsCode(code: string, challenge: string): Promise<void> {
        if (this.inProgress) {
            return;
        }
        this.error = undefined;
        this.inProgress = true;
        try {
            await this.authService.login({ code, challenge } satisfies OAuthWithMfaSms);

            if (this.state.mfaStatus === MfaStatus.MfaSetupRequired) {
                await this.client.setSmsMfaEnabled();
            }

            this.handleVerifyAccepted();
        } catch (e) {
            this.handleVerifyError(ensureUfRequestError(e));
        } finally {
            this.inProgress = false;
        }
	}

    protected async verifyVirtualMfaToken(token: string): Promise<void> {
        if (this.inProgress) {
            return;
        }
        this.error = undefined;
        this.inProgress = true;
        try {
            await this.authService.login({ mfa_token: token } satisfies OAuthWithVirtualMfa);
            this.handleVerifyAccepted();
        } catch (e) {
            this.handleVerifyError(ensureUfRequestError(e));
        } finally {
            this.inProgress = false;
        }
    }

    protected logout() {
        if (this.inProgress) {
            return;
        }
        void this.authService.logout();
    }

    private handleVerifyAccepted() {

        if (this.mfaStatus === MfaStatus.MfaSetupRequired && this.context.account?.mfa?.hasRecoveryCodes === false) {
            this.challenge = MfaChallengeType.RecoveryCode;
            this.acceptedChallenges = [MfaChallengeType.RecoveryCode];

            return;
        }

        void this.router.navigateByUrl(this.route.snapshot.params.next || '/');
    }

    private handleVerifyError(error: UfRequestError): void {

        if (isPasswordChangeRequiredErrorData(error.data) && this.state.password) {
            const params = this.route.snapshot.params.next ? { next: this.route.snapshot.params.next }: {};

            void this.router.navigate(['/', UrlSegments.PasswordChange, params], { state: { oldPassword: this.state.password } satisfies PasswordChangeComponentNavigationState });

            return;
        }

        this.error = error.message;
    }

    private async setUpMfa() {
        try {
            const tenantName = (await this.tenantClient.getSettings()).name;

            if (!this.context.account?.username) {
                throw new Error('No username');
            }

            this.label = `(${tenantName}) ${this.context.account.username}`;

        } catch (e) {
            const error = ensureUfRequestError(e);

            this.error = error.message;
        }
    }

    private createAcceptedChallenges(): `${MfaChallengeType}`[] {

        let acceptedChallenges: `${MfaChallengeType}`[] = [];

        if (this.state.acceptedChallenges) {
            acceptedChallenges = this.state.acceptedChallenges.split(',').filter((challenge): challenge is `${MfaChallengeType}` => isValueOfStringEnumType(MfaChallengeType)(challenge));
        } else {
            acceptedChallenges = Object.values(MfaChallengeType);

            /* remove setup sms if tenant doesn't support sms
             * tenant settings are only available for setup, not for verify
            */
            if (!this.context.tenantSettings?.isSmsMfaEnabled) {
                acceptedChallenges = acceptedChallenges.filter((challenge) => challenge !== MfaChallengeType.Sms);
            }

            // TODO remove setup virtual mfa if tenant doesn't support virtual mfa

        }

        return acceptedChallenges;
    }

}
